<template>
  <div class="LogisticsDeliver-container" ref="chart"></div>
</template>
  <script>
import * as echarts from "echarts";
export default {
  name: "ProductExcellence",

  data() {
    return {};
  },
  mounted() {
    this.echartsInit();
  },
  methods: {
    echartsInit() {
      const myChart = echarts.init(this.$refs.chart);

      var option = {
        tooltip: {
           formatter: function({name,value}) {
              return `${name}:${value}%`
            }
        },
        animation: false,
        grid: {
          top: "40",
          left: "5%",
          right: "5%",
          bottom: "8%",
          containLabel: true,
        },
        xAxis: {
          data: ["鱼缸", "系统", "配件", "生态"],
          axisLine: {
            show: true,
            lineStyle: {
              color: "#11417a",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            margin: 14,
            fontSize: 14,

            textStyle: {
              color: "#A3C0DF",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            gridIndex: 0,
            min: 0,
            max: 100,
            interval: 25,
            // splitNumber: 4,

            splitLine: {
              show: true,
              lineStyle: {
                color: "#113763",
                width: 1,
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              formatter: "{value}%",
              lineStyle: {
                color: "#11417a",
              },
            },
            axisLabel: {
              show: true,
              margin: 14,
              fontSize: 12,
              formatter: "{value}%",
              textStyle: {
                color: "#A3C0DF",
              },
            },
          },
        ],
        series: [
          {
            name: "鱼缸",
            type: "bar",
            barWidth: 20,

            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#07ecd9",
                  },
                  {
                    offset: 1,
                    color: "#034881",
                  },
                ]),
              },
            },
            data: [98, 90, 100,100],
            z: 10,
            zlevel: 0,
            label: {
              show: true,
              position: "top",
              distance: 10,
              fontSize: 12,
              color: "#01fff4",

              formatter: function (params) {
                return params.value + '%'
              },
            },
          },
          {
            // 分隔
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: "#0F375F",
              },
            },
            symbolRepeat: "fixed",
            symbolMargin: 6,
            symbol: "rect",
            symbolClip: true,
            symbolSize: [20, 2],
            symbolPosition: "start",
            symbolOffset: [0, -1],
            // symbolBoundingData: this.total,
            data: [98, 90, 100, 100],
            width: 25,
            z: 0,
            zlevel: 1,
          },
        ],
      };

      myChart.setOption(option);
    },
  },
};
</script>
  <style lang="scss" scoped>
.LogisticsDeliver-container {
  width: 100%;
  height: 100%;
}
</style>
  