<template>
  <div class="LogisticsDeliver-container">
    <ChartenClosure :option="option"></ChartenClosure>
  </div>
</template>
<script>
import * as echarts from "echarts";
import ChartenClosure from "@/components/ChartenClosure.vue";

export default {
  name: "LogisticsDelivery",
  components: {
    ChartenClosure,
  },
  data() {
    return {
      option: {
        // backgroundColor: "#05224d",
        tooltip: {},
        grid: {
          top: '40',
          left: '5%',
          right: '5%',
          bottom: '8%',
          containLabel: true,
        },
        legend: {
          itemGap: 50,
          top: '5px',
          data: ['到货量', '在途量'],
          textStyle: {
            color: '#f9f9f9',
            borderColor: '#fff'

          },
        },
        xAxis: [{
          type: 'category',
          boundaryGap: true,
          axisLine: {
            show: true,

          },
          axisLabel: {
            textStyle: {
              color: '#d1e6eb',
              margin: 15,
            },
          },
          axisTick: {
            show: false,
          },
          data: ['1-5', '6-10', '11-15', '16-20', '21-25','26-31'],
        }],
        yAxis: [{
          type: 'value',
          min: 0,
          splitNumber: 7,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#0a3256'
            }
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            textStyle: {
              color: '#d1e6eb',

            },
          },
          axisTick: {
            show: false,
          },
        }],
        series: [{
          name: '到货量',
          type: 'line',
          // smooth: true, //是否平滑曲线显示
          // 			symbol:'circle',  // 默认是空心圆（中间是白色的），改成实心圆
          showAllSymbol: true,
          symbol: 'emptyCircle',
          symbolSize: 6,
          lineStyle: {
            normal: {
              color: "#28ffb3", // 线条颜色
            },
            borderColor: '#f0f'
          },
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#fff',
            }
          },
          itemStyle: {
            normal: {
              color: "#28ffb3",

            }
          },
          tooltip: {
            show: false
          },
          areaStyle: { //区域填充样式
            normal: {

              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(0,154,120,1)'
              },
                {
                  offset: 1,
                  color: 'rgba(0,0,0, 0)'
                }
              ], false),
              shadowColor: 'rgba(53,142,215, 0.9)',
              shadowBlur: 20
            }
          },
          data: [21, 25, 19, 30, 35,41]
        }, {
          name: '在途量',
          type: 'bar',
          barWidth: 20,
          tooltip: {
            show: false
          },
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#fff',
            }
          },
          itemStyle: {
            normal: {
              // barBorderRadius: 5,
              // color: new echarts.graphic.LinearGradient(
              //     0, 0, 0, 1,
              //     [{
              //             offset: 0,
              //             color: '#14c8d4'
              //         },
              //         {
              //             offset: 1,
              //             color: '#43eec6'
              //         }
              //     ]
              // )
              color: function (params) {
                var colorList = ['#0ec1ff', '#10cdff', '#12daff', '#15ebff', '#17f8ff', '#1cfffb', '#1dfff1'];
                return colorList[params.dataIndex];
              }
            }
          },
          data: [32, 31, 36, 28, 26,30]
        }]
      }
    };
  },
};
</script>
<style lang="scss" scoped>
.LogisticsDeliver-container {
  width: 100%;
  height: 100%;
}
</style>
