<template>
  <div class="background-box">
    <div class="title-box">
      {{ title }}
      <slot name="right"></slot>
    </div>
    <div class="content-box">
      <div class="cont-bg"> <slot></slot></div>
     
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      类型: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.background-box {
  position: relative;
  // background-image: url("/src/assets/line.png");
  background-size: 100% 100%;
  /* padding: 10px; */
  width: 100%;
  height: 100%;
  .cont-bg {
    width: 100%;
    height: 100%;
    background-image: url("/src/assets/lin2.png");
  }
  .title-box {
    height: 3.5vh;
    line-height: 3.5vh;
    width: 100%;
    padding-top: 2vh;
    // background: linear-gradient(
    //   to right,
    //   #0da3c2 0%,
    //   #0d6988 50%,
    //   #0d6988 50%,
    //   #092842 100%
    // );
    color: #73ffcd; /* 文字颜色为白色 */
    text-align: left;
    text-indent: 1em;

    // font-size: 0.20rem;
    padding: 0.03rem 0.1rem;
    margin-bottom: 1.5vh;
  }

  .content-box {
    // padding: 10px;
    width: 100%;
    height: calc(100% - 5vh);
    position: absolute;
    background-color: rgba(17, 95, 182, 0.2);
  }
}
</style>
