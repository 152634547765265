<template>
  <div class="LogisticsDeliver-container">
    <ChartenClosure :option="option"></ChartenClosure>
  </div>
</template>
<script>
import * as echarts from "echarts";
import ChartenClosure from "@/components/ChartenClosure.vue";
export default {
  name: "WaterTemperature",
  components: {
    ChartenClosure,
  },
  props: {
    address: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    option() {
      const addressOption = {
        华北: ["北京", "天津", "河北", "山西", "内蒙古"],
        东北: ["辽宁", "吉林", "黑龙江", "大连"],
        华东: [
          "上海",
          "江苏",
          "浙江",
          "安徽",
          "福建",
          "江西",
          "山东",
          "宁波",
          "夏门",
          "青岛",
        ],
        华南: ["河南", "湖北", "湖南", "广东", "广西", "海南", "深圳"],
        西南: ["重庆", "四川", "贵州", "云南", "西藏"],
        西北: ["陕西", "甘肃", "青海", "宁夏", "新疆"],
      };

      const addressDataOption = {
        华北: {
          pH: [6.77, 6.8, 6.4, 7.2, 7.0],
          水温: [22.3, 21.1, 23.11, 21.2, 21.0],
        },
        东北: {
          pH: [6.2, 6.7, 6.5, 7.3],
          水温: [23.2, 22.5,21.2,20.37],
        },
        华东: {
          pH: [5.7, 5.6, 5.9, 6.4, 6.5, 6.2, 6.3,7, 6.8, 6.9],
          水温: [25.3, 26.7, 23.2, 28.7, 27.3,22.5, 26.9, 28.6, 23.4,20.3],
        },
        华南: {
          pH: [6.8, 5.9, 6.7, 7, 7, 6.8, 7.5],
          水温: [23.2, 25.3, 23.2, 25.3, 26.2, 26.3,21.2],
        },
        西南: {
          pH: [6.8, 7.3, 6.7, 6, 6.8, 7.2],
          水温: [26.4, 27.6, 29.4,27.53, 25.0],
        },
        西北: {
          pH: [7.2, 7.1, 7.3, 7.0, 6.8, 6.7],
          水温: [21.32,22.2, 21.3, 21.4,22.5],
        },
      };
      return {
        tooltip: {},
        grid: {
          top: "40",
          left: "0%",
          right: "0%",
          bottom: "0%",
          containLabel: true,
        },
        legend: {
          itemGap: 50,
          data: ["pH", "水温"],
          textStyle: {
            color: "#f9f9f9",
            borderColor: "#fff",
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            axisLine: {
              show: true,
              // lineStyle: {
              //     color: '#f9f9f9'
              // },
            },
            axisLabel: {
              // rotate: this.address === "华东" ? 45 : 0,
              rotate:45,

              textStyle: {
                color: "#d1e6eb",
                margin: 15,
              },
            },
            axisTick: {
              show: false,
            },
            data: addressOption[this.address],
          },
        ],
        yAxis: [
          {
            type: "value",
            // name:"pH",
            max:12,
            min: 4,
            splitNumber: 7,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#0a3256",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              // margin: 20,
              textStyle: {
                color: "#d1e6eb",
              },
            },
            axisTick: {
              show: false,
            },
          },
          {
            type: "value",
            // name:"水温",
            max:30,
            min: 0,
            // splitNumber: 7,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#0a3256",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              // margin: 20,
              textStyle: {
                color: "#d1e6eb",
                
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "pH",
            type: "line",
      
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 6,
            lineStyle: {
              normal: {
                color: "#ffe000",
              },
              borderColor: "#f0f",
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
              },
            },
            itemStyle: {
              normal: {
                color: "#ffe000",
              },
            },
            tooltip: {
              show: false,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(255, 224, 0,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,0,0, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(53,142,215, 0.9)",
                shadowBlur: 20,
              },
            },
            data: addressDataOption?.[this.address]?.["pH"],
          },
          {
            name: "水温",
            type: "line",
            yAxisIndex: 1,
        
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 6,
            lineStyle: {
              normal: {
                color: "#5470c6", // 线条颜色
              },
              borderColor: "#5045f6",
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
              
              },
            },
            itemStyle: {
              normal: {
                color: "#5470c6",
              },
            },
            tooltip: {
              show: false,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(80, 69, 246,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,0,0, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(80, 69, 246, 0.9)",
                shadowBlur: 20,
              },
            },
            data: addressDataOption?.[this.address]?.["水温"],
          },
        ],
      };
    },
  },
  watch: {
    address(val) {
      console.log(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.LogisticsDeliver-container {
  width: 100%;
  height: 100%;
}
</style>
