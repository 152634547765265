<template>
  <div class="background-frame" ref="chart"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "ChartenClosure",
  props: {
    option: {
      type: Object,
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  mounted() {
    this.echartsInit();
    //  window.addEventListener('resize',()=>{
    //   this.myChart.resize()
    //  })
    
  },
  watch: {
    option: {
      handler() {
        this.myChart && this.myChart.setOption(this.option);
      },
    },
  },
  methods: {
    echartsInit() {
      this.myChart = echarts.init(this.$refs.chart);
      this.myChart.setOption(this.option);
    },
  },
};
</script>
<style lang="scss" scoped>
.background-frame {
  width: 100%;
  height: 100%;
}
</style>
