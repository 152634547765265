<template>
  <div class="LogisticsDeliver-container" ref="chart"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "ProspectiveDialysis",
  components: {},

  data() {
    return {};
  },
  mounted() {
    this.echartsInit();
  },
  methods: {
    echartsInit() {
      const myChart = echarts.init(this.$refs.chart);
      // 数据

      var option = {
        //你的代码
        // backgroundColor: "#05224d",
        tooltip: {
         
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.5)",
           borderColor: "none",// 无效
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
          },
        },

        grid: {
          top: "40",
          left: "5%",
          right: "5%",
          bottom: "8%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["1-5", "6-10", "11-15", "16-20"],
            axisLine: {
              show: true,
              lineStyle: {
                color: "#063374",
                width: 1,
                type: "solid",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(255,255,255,0.8)",
                fontSize: 14,
              },
            },
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              color: "rgba(255,255,255,0.8)",
            },
            type: "value",
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "rgba(255,255,255,0.8)",
                fontSize: 14,
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#063374",
                width: 1,
                type: "solid",
              },
            },
            splitLine: {
              lineStyle: {
                color: "#0a3256",
                type: "",
              },
            },
          },
        ],
        series: [
          {
            name: "裂变用户",
            type: "bar",
            data: [90, 95, 120, 110, 98, 130],
            barWidth: 8, //柱子宽度
            barGap: 1, //柱子之间间距
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#469f68",
                  },
                  {
                    offset: 1,
                    color: "rgba(70, 159, 104, 0.1)",
                  },
                ]),
                opacity: 1,
              },
            },
          },
          {
            name: "裂变人员",
            type: "bar",
            data: [22, 20, 25, 28, 25, 22],
            barWidth: 8,
            barGap: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#387fc8",
                  },
                  {
                    offset: 1,
                    color: "rgba(56, 127, 200, 0.1)",
                  },
                ]),
                opacity: 1,
              },
            },
          },
          {
            name: "预览用户",
            type: "bar",
            data: [17, 18, 13, 18, 13, 17],
            barWidth: 8,
            barGap: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#4db0a9",
                  },
                  {
                    offset: 1,
                    color: "rgba(77, 176, 169, 0.1)",
                  },
                ]),
                opacity: 1,
              },
            },
          },
          {
            name: "成交客户",
            type: "bar",
            data: [22, 20, 25, 28, 25, 22],
            barWidth: 8,
            barGap: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#0291FF",
                  },
                  {
                    offset: 1,
                    color: "rgba(12, 135, 230, 0.1)",
                  },
                ]),
                opacity: 1,
              },
            },
          },
        ],
      };

      myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.LogisticsDeliver-container {
  width: 100%;
  height: 100%;
  //   background-color: black;
}
</style>
