<template>
  <div class="CustomerProblem-container">
    <div class="tilte-box">
      <div>客户</div>
      <div>地点</div>
      <div>联系方式</div>
      <div>日期</div>
      <div>类型</div>
      <div>问题</div>
      <div>效率</div>
      <div>评价</div>
    </div>
    <div class="title-containor" id="scroll-container">
      <ul v-for="(item, index) in correspondent" :key="index" id="scroll-list">
        <li class="slide-item">{{ item.name[0] + '...' }}</li>
        <li>{{ item.palace }}</li>
        <li class="phone-number">{{ item.phone | maskPhoneNumber }}</li>
        <li>{{ item.date }}</li>
        <li>{{ item.serive }}</li>
        <!-- <li class="icon"><img :src="item.appraise" /></li> -->
        <li>{{ item.reason }}</li>
        <li>{{ item.solutionefficiency }}</li>
        <li>{{ item.appraise }}</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomerProblem",
  data() {
    return {
      correspondent: [],
    };
  },
  mounted() {
    this.generateMockData();


    var palaces = this.correspondent.map(function (item) {
          return item.palace;
        }
    );
    console.log(palaces);
    const regex = /市(.*)$/;
    const result = palaces.replace(regex, "市...")
    console.log(result, '看看');
  },
  methods: {
    textSlide() {

      const slider = document.querySelector('.title-containor');
      const sliderList = document.getElementById('scroll-list');
      const sliderItems = sliderList.querySelectorAll('li');
      console.log("12312", slider, sliderList, sliderItems)
      console.log("77777777", sliderItems)
      console.log("888888888888", slider.offsetWidth)
      console.log("9999999999999", this.correspondent.length)
      let scrollPosition = 0;
      let currentIndex = 0;
      setInterval(() => {
        currentIndex = (currentIndex + 1) % sliderItems.length;
        scrollPosition = currentIndex * slider.offsetWidth;

        sliderList.style.transform = `translateX(-${scrollPosition}px)`;
      }, 0);
    },
    generateMockData() {
      const names = ["唐", "陈", "李", "姚", "曾", "郭", "关", "张", "王", "何", "刘", "杨", "黄", "赵", "吴", "周", "徐", "孙", "马", "朱", "胡", "郑", "林", "蔡", "梁", "高", "洪", "韩", "段", "钟", "谢", "魏", "吕", "封", "彭"];
      const palaces = ["广州市增城区", "广州市白云区", "深圳市南山区", "深圳市龙岗区", "深圳市光明区", "深圳市福田区", "佛山市高明区", "佛山市三水区", "佛山市顺德区", "佛山市南海区", "佛山市禅城区", "广州市越秀区", "广州市天河区", "广州市花都区", "广州市黄埔区", "广州市番禺区"];
      const services = ["线上", "线下"];
      const appraises = ["5星", "3星", "3.5星", "4星", "4.5星", "5星"];
      const reasons = ["球阀", "水泵", "智控系统", "氧气泵", "加热棒", "电磁阀", "传感器", "水温传感器", "温度传感器", "电源", "灯光", "小程序", "清洁服务", "售后服务"];
      const solutionEfficiencies = ["1天", "1.5天", "2天"];
      let data = [];
      for (let i = 0; i < 45; i++) {
        let num = i + 1;
        num=num/2;
        if (num % 2 || num % 3) {
          num = num - Math.floor(Math.random() * 2);
        }
        const min = 3099999999;
        const max = 9500000000;
        data.push({
          name: names[Math.floor(Math.random() * names.length)],
          palace: palaces[Math.floor(Math.random() * palaces.length)],
          phone: `1${Math.floor(Math.random() * (max - min) + min)}`,
          date: this.getDaysAgo(num),
          serive: services[Math.floor(Math.random() * services.length)],
          appraise: appraises[Math.floor(Math.random() * appraises.length)],
          solutionefficiency: solutionEfficiencies[Math.floor(Math.random() * solutionEfficiencies.length)],
          reason: reasons[Math.floor(Math.random() * reasons.length)],
        });
      }
      this.correspondent = data;
      this.textSlide();
    },
    getDaysAgo(num) {
      var currentDate = new Date();

      // 获取当前日期的前14天日期
      var sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - num);

      // 获取前七天的月份和日期
      var month = (sevenDaysAgo.getMonth() + 1).toString().padStart(2, '0'); // 月份需要加1，然后保证两位数字
      var day = sevenDaysAgo.getDate().toString().padStart(2, '0'); // 获取日期，保证两位数字

      // 构造 MM-DD 格式的字符串
      return month + '月' + day + '日';
    },
  },
  filters: {
    maskPhoneNumber(phoneNumber) {
      // console.log(phoneNumber);
      return phoneNumber.replace(/\d{4}(?=\d{4}$)/, '**')

    },


  }
};
</script>
<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0 !important;
}

.CustomerProblem-container {
  width: 100%;
  height: 100%;

  // background-color: #05224d;
  .tilte-box {
    display: fixed;
    margin-top: 2vh;
    width: 100%;
    height: 4vh;
    display: flex;
    justify-content: space-around;
    text-align: center;
    line-height: 4vh;
    color: #03c2ec;
    font-weight: 700;

    div {
      width: 6.2vw;
      // background-color: #1e3f75;
      font-size: 1vw;
    }

  }

  .title-containor {
    width: 100%;
    height: 18vh;
    // background-color: #4a751e;
    overflow-y: scroll;
    overflow: hidden;


    ul {
      display: flex;
      justify-content: space-around;
      text-align: center;
      font-size: 1vw;
      color: #fff;
      //transition: transform 0.5s linear;
      transition: all 1.3s linear 0s;

      li {
        margin-top: 1.5vh;
        height: 2.5vh;
        line-height: 2.5vh;
        width: 100%;
        // background-color: #1e3f75;

        overflow: hidden;

        white-space: nowrap;

        text-overflow: ellipsis;
      }

      .icon {
        img {
          width: 1vw;
          height: 2vh;
        }
      }
    }

    ul:nth-child(even) {
      color: #28ffb3;
    }
  }
}

#scroll-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  animation: scroll-up 60s linear infinite;
}

@keyframes scroll-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-3800%);
  }
}
</style>
