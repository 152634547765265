<template>
  <div class="big-data-logic">
    <img src="@/assets/Group 273@2x.png" alt="">
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.big-data-logic {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>