<template>
  <div class="head-container">
    <div class="bg_header">
    <div class="title">  {{ title }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TitlePage",
  data() {
    return {
      title: "蓝得智能数字化管理系统",
    };
  },
};
</script>
<style lang="scss" scoped>
.head-container {
  text-align: center;
  width: 100%;
 height: 100%;
  color: #fff;
  .bg_header {
    width: 100%;
    height: 8vh;
    background: url('/src/assets/title.png');
    background-size: 100% 100%;
    .title {
      text-align: center;
            font-size: 2.2vw;
            line-height: 8vh;
            color: #fff;
        }
    }
  }
  

</style>
