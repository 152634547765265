<template>
  <div class="LogisticsDeliver-container">
    <ChartenClosure :option="option"></ChartenClosure>
  </div>
</template>
<script>
import * as echarts from "echarts";
import ChartenClosure from "@/components/ChartenClosure.vue";

export default {
  name: "WaterTemperature",
  components: {
    ChartenClosure,
  },
  props: {
    address: {
      type: String,
    },
    
  },
  data() {
    return {};
  },
  methods:{
    getSevenDaysAgo(){
      var currentDate = new Date();

      // 获取当前日期的前七天日期
      var sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 15);

      // 获取前七天的月份和日期
      var month = (sevenDaysAgo.getMonth() + 1).toString().padStart(2, '0'); // 月份需要加1，然后保证两位数字
      var day = sevenDaysAgo.getDate().toString().padStart(2, '0'); // 获取日期，保证两位数字

      // 构造 MM-DD 格式的字符串
      return month + '-' + day;
    },
    get14DaysAgo(){
      var currentDate = new Date();

      // 获取当前日期的前14天日期
      var sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 30);

      // 获取前七天的月份和日期
      var month = (sevenDaysAgo.getMonth() + 1).toString().padStart(2, '0'); // 月份需要加1，然后保证两位数字
      var day = sevenDaysAgo.getDate().toString().padStart(2, '0'); // 获取日期，保证两位数字

      // 构造 MM-DD 格式的字符串
      return month + '-' + day;
    },
    getDaysAgo(){
      var currentDate = new Date();


      var sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() );

      // 获取前七天的月份和日期
      var month = (sevenDaysAgo.getMonth() + 1).toString().padStart(2, '0'); // 月份需要加1，然后保证两位数字
      var day = sevenDaysAgo.getDate().toString().padStart(2, '0'); // 获取日期，保证两位数字

      // 构造 MM-DD 格式的字符串
      return month + '-' + day;
    },
  },
  computed: {
    option() {
      let daysAgo=this.getDaysAgo()
      let days7Ago=this.getSevenDaysAgo()
      let days14Ago=this.get14DaysAgo()

      const addressOption = {
        华北: [days14Ago,days7Ago,daysAgo],
        东北: [days14Ago,days7Ago,daysAgo],
        华东: [
          days14Ago,days7Ago,daysAgo
        ],
        华南: [days14Ago,days7Ago,daysAgo],
        西南: [days14Ago,days7Ago,daysAgo],
        西北: [days14Ago,days7Ago,daysAgo],
      };

      const addressDataOption = {
        华北: {
          pH: [6.5, 6.8, 7.2,],
          水温: [24.1, 28.4,21.2,],
        },
        东北: {
          pH: [5.7, 6, 6.7, 7.5],
          水温: [28.3, 24.5, 22.3,],
        },
        华东: {
          pH: [6.5, 6.7, 7.2, ],
          水温: [26.5, 24.8, 19.2,],
        },
        华南: {
          pH: [7.3, 7, 6.8, ],
          水温: [27.6, 23.1, 18.3, ],
        },
        西南: {
          pH: [5.6, 5.9, 6, ],
          水温: [23.1, 28.7, 22.7],
        },
        西北: {
          pH: [7.0, 7.3, 7.2,],
          水温: [24.6, 27.9, 23.9],
        },
      };
      return {
        tooltip: {},
        grid: {
          top: "40",
          left: "5%",
          right: "5%",
          bottom: "8%",
          containLabel: true,
        },
        legend: {
          itemGap: 50,
          data: ["pH", "水温"],
          textStyle: {
            color: "#f9f9f9",
            borderColor: "#fff",
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            axisLine: {
              show: true,
              // lineStyle: {
              //     color: '#f9f9f9'
              // },
            },
            axisLabel: {
              // rotate: this.address === "华东" ? 45 : 0,
              // rotate:45,

              textStyle: {
                color: "#d1e6eb",
                margin: 15,
              },
            },
            axisTick: {
              show: false,
            },
            data: addressOption[this.address],
          },
        ],
        yAxis: [
          {
            type: "value",
            // name:"pH",
            max:14,
            min: 0,
            splitNumber: 7,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#0a3256",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              // margin: 20,
              textStyle: {
                color: "#d1e6eb",
              },
            },
            axisTick: {
              show: false,
            },
          },
          {
            type: "value",
            // name:"水温",
            max:30,
            min: 0,
            // splitNumber: 7,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#0a3256",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              // margin: 20,
              textStyle: {
                color: "#d1e6eb",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "pH",
            type: "line",
      
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 6,
            lineStyle: {
              normal: {
                color: "#ffe000",
              },
              borderColor: "#f0f",
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
              },
            },
            itemStyle: {
              normal: {
                color: "#ffe000",
              },
            },
            tooltip: {
              show: false,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(255, 224, 0,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,0,0, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(53,142,215, 0.9)",
                shadowBlur: 20,
              },
            },
            data:  addressDataOption?.[this.address]?.["pH"],
          },
          {
            name: "水温",
            type: "line",
            yAxisIndex: 1,
        
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 6,
            lineStyle: {
              normal: {
                color: "#5470c6", // 线条颜色
              },
              borderColor: "#5045f6",
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
              },
            },
            itemStyle: {
              normal: {
                color: "#5470c6",
              },
            },
            tooltip: {
              show: false,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(80, 69, 246,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,0,0, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(80, 69, 246, 0.9)",
                shadowBlur: 20,
              },
            },
            data: addressDataOption?.[this.address]?.["水温"],
          },
        ],
      };
    },
  },
  watch: {
    address(val) {
      console.log(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.LogisticsDeliver-container {
  width: 100%;
  height: 100%;
}
</style>
