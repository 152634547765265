<template>
  <div class="LogisticsDeliver-container" ref="chart"></div>
</template>
  <script>
import * as echarts from "echarts";
export default {
  name: "ProductStatistics",
  components: {},

  data() {
    return {};
  },
  mounted() {

    this.echartsInit();
  },
  methods: {
    echartsInit() {
   
      const myChart = echarts.init(this.$refs.chart);
      const trafficWay = [
        {
          name: "生态",
          value: 500,
        },
        {
          name: "鱼缸",
          value: 80,
        },
        {
          name: "配件",
          value: 45,
        },
        {
          name: "系统",
          value: 62,
        },
      ];
      const total = trafficWay.reduce((sum, item) => sum + item.value, 0);
      console.log(total, "总数");
      // const percentages = trafficWay.map((item) => ({
      //   percentage: ((item.value / total) * 100).toFixed(2) + "%",
      // }));

      let data = [];
      let legendData = [];
      var color = [
        "#387fc8",
        "#6496cb",
        "#4db0a9",
        "#469f68",
        "#67b088",
        "#85b96f",
        "#6e80c1",
      ];
      for (var i = 0; i < trafficWay.length; i++) {
        let name = trafficWay[i].name + "  " + trafficWay[i].value;
        legendData.push(name);
        data.push(
          {
            value: trafficWay[i].value,
            name: name,
            itemStyle: {
              borderWidth: 0,
              borderRadius: 10,
              shadowBlur: 10,
              borderColor: color[i],
              shadowColor: color[i],
            },
          },
          {
            value: total / 100,
            name: "",
            itemStyle: {
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
              color: "rgba(0, 0, 0, 0)",
              borderColor: "rgba(0, 0, 0, 0)",
              borderWidth: 0,
            },
          }
        );
      }
      let seriesOption = [
        {
          name: "",
          type: "pie",
          clockwise: false,
          radius: ["50%", "70%"],
          center: ["30%", "50%"],
          emphasis: {
            scale: false,
          },
          label: {
            show: false,
          },
          data: data,
        },
      ];
    const option = {
        title: {
          // text: "本月总量",
          subtext: total,
          textStyle: {
            color: "#19E1E3",
            fontSize: 12,
            padding: [0, 0, 0, 0],
          },
          subtextStyle: {
            fontSize:16,
            fontWeight: "bolder",
            color: "#19E1E3",
          },
          x: "20%",
          y: "40%",
        },
        color: color,
        tooltip: {
          show: false,
        },
        legend: {
          icon: "rect",
          itemWidth: 16,
          itemHeight: 8,
          itemStyle: {
            borderWidth: 3,
          },
          orient: "vertical",
          data: legendData,
          formatter: function (value) {
            const number = parseInt(value.match(/\d+/)[0]);
            const regex = /[\u4e00-\u9fa5]+/;
            const match = value.match(regex);
            if (match) {
              const text = match[0];
              console.log(text); // 输出："生态"
            } else {
              console.log("未找到匹配的文字");
            }

            const total = trafficWay.reduce((sum, item) => sum + item.value, 0);
            const percentage = ((number / total) * 100).toFixed(2) + "%";

            return `${match}   ${percentage}`;
          },
          right: "0%",
          top: "center",
          align: "left",
          textStyle: {
            color: "#fff",
            fontSize: 10,
            padding: [0, 0, 0, 0],
          },
          itemGap: 20, // 图例之间的间隔
        },
        toolbox: {
          show: false,
        },
        series: seriesOption,
      };

      myChart.setOption(option);
    },
  },
};
</script>
  <style lang="scss" scoped>
.LogisticsDeliver-container {
  width: 100%;
  height: 100%;
}
</style>
  