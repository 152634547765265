<template>
  <div class="container-wrap">
    <div class="title">
      <TitlePage></TitlePage>
    </div>
    <div class="main-container">
      <div class="left-container">
        <div class="prodeucts-container">
          <div class="prodeuts-item">
            <div class="prodeuts-childone">
              <ChartenTitle :title="productstatistics">
                <ProductStatistics></ProductStatistics>
              </ChartenTitle>
            </div>
            <div class="prodeuts-childtwo">
              <ChartenTitle :title="productexcellence">
                <ProductExcellence></ProductExcellence>
              </ChartenTitle>
            </div>
          </div>
        </div>
        <div class="light-container">
          <ChartenTitle :title="logisticsdelivery">
            <LogisticsDelivery></LogisticsDelivery>
          </ChartenTitle>
        </div>
        <div class="prospective-container">
          <ChartenTitle :title="prospectivedialysis">
            <ProspectiveDialysis></ProspectiveDialysis>
          </ChartenTitle>
        </div>
      </div>
      <div class="map-container">
        <div class="map">
          <ChinaMap></ChinaMap>
        </div>
        <div class="problem">
          <ChartenTitle :title="customerProblem">
            <CustomerProblem></CustomerProblem>
          </ChartenTitle>
        </div>
      </div>
      <div class="right-container">
        <div class="bigdata-container">
          <!-- <ChartenTitle :title="bigdataogic"> -->
            <BigDataLogic />
          <!-- </ChartenTitle> -->
        </div>
        <div class="prodeucts-container">
          <div class="prodeuts-item">
            <div class="prodeuts-childone">
              <ChartenTitle :title="watertemperature">
                <template #right>
                  <div class="slesct-container">
                    <select id="regionSelector" v-model="address" >
                      <option value="华南">华南地区</option>
                      <option value="华北">华北地区</option>
                      <option value="东北">东北地区</option>
                      <option value="华东">华东地区</option>

                      <option value="西南">西南地区</option>
                      <option value="西北">西北地区</option>
                    </select>
                  </div>
                  <div id="chartContainer"></div>
                </template>

                <WaterTemperature :address="address"> </WaterTemperature>
              </ChartenTitle>
            </div>
            <div class="prodeuts-childtwo">
              <ChartenTitle :title="watertemperature">
                   <template #right>
                  <div class="slesct-container">
                    <select id="regionSelector" v-model="regional" >
                      <option value="华南">华南地区</option>
                      <option value="华北">华北地区</option>
                      <option value="东北">东北地区</option>
                      <option value="华东">华东地区</option>

                      <option value="西南">西南地区</option>
                      <option value="西北">西北地区</option>
                    </select>
                  </div>
                  <div id="chartContainer"></div>
                </template>
                <WaterTemperatureTrends :address="regional"> </WaterTemperatureTrends>
              </ChartenTitle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ChartenTitle from '@/components/ChartenTitle.vue'
import ChinaMap from "./components/ChinaMap/index.vue";
import CustomerProblem from "./components/CustomerProblem/index.vue";
import ProductStatistics from "./components/ProductStatistics/ProductStatistics.vue";
import BigDataLogic from "./components/BigDataLogic/BigDataLogic";
import ChartenTitle from "@/components/ChartenTitle.vue";
import ProspectiveDialysis from "./components/ProspectiveDialysis/ProspectiveDialysis.vue";
import ProductExcellence from "./components/ProductExcellence/ProductExcellence.vue";
import WaterTemperature from "./components/WaterTemperature/index.vue";
import LogisticsDelivery from "./components/LogisticsDelivery/index.vue";
// import RainbowLight from './components/RainbowLight/RainbowLight.vue'
import TitlePage from './components/TitlePage/index.vue'
import WaterTemperatureTrends from './components/WaterTemperatureTrends'
export default {
  name: "MapView",
  components: {
    ChartenTitle,
    ProspectiveDialysis,
    BigDataLogic,
    // ChinaMap:()=>import('./components/ChinaMap/index.vue'),
    ChinaMap,
    CustomerProblem,
    ProductStatistics,
    ProductExcellence,
    WaterTemperature,
    LogisticsDelivery,
    WaterTemperatureTrends,
    // RainbowLight,
    TitlePage
  },

  data() {
    return {
      // bigdataogic: "大数据逻辑",
      customerProblem: "近期客户突发问题",
      prospectivedialysis: "潜在用户透析",
      productstatistics: "销售比例",
      productexcellence: "优良程度",
      watertemperature: "水质和水温",
      logisticsdelivery: "物流交付",
      // rainbowlight:'产品质量统计',
address:"华北",
regional:'华北'

    };
  },
  methods:{
  
  }
};
</script>
<style lang="scss" scoped>
.container-wrap {
  width: 100vw;
  height: 100vh;
  // background-color: red;
  background: url("/src/assets/bg3.png") no-repeat;
  background-size: 100% 100%;
  .title {
    width: 100vw;
    height: 12vh;
    min-width: 13.66vw;
  }
  .main-container {
    height: calc(100% - 8vh);
    width: 100%;

    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    .left-container {
      margin-left: 1vw;
      width: 25vw;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
   
      .prodeucts-container {
        width: 100%;
        height: 25vh;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .title-style {
          font-size: 1vw;
          color: #03c2ec;
          // margin-left: 2vw;
          font-weight: 700;
        }
        .prodeuts-item {
          width: 100%;
          height: 22vh;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          .prodeuts-childone {
            width: 12vw;
            height: 100%;
          }
          .prodeuts-childtwo {
            width: 12vw;
            height: 100%;
          }
        }
      }
      .prospective-container {
        width: 100%;
        height: 35vh;

        .title-style {
          font-size: 1vw;
          color: #03c2ec;
          // margin-left: 2vw;
          font-weight: 700;
          padding-top: 2vh;
          margin-bottom: 2vh;
        }
      }
    }
    .light-container {
      width: 100%;
      height: 25vh;
    }
    .map-container {
      width: 40vw;
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .map {
        width: 100%;
        height: 56vh;
        
      }
      .problem {
        width: 100%;
        height: 30vh;

        .title-style {
          font-size: 1vw;
          color: #03c2ec;
          // margin-left: 2vw;
          font-weight: 700;
          padding-top: 2vh;
        }
      }
    }
    .right-container {
      margin-right: 1vw;
      width: 25vw;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      .title-style {
        font-size: 1vw;
        color: #03c2ec;
        // margin-left: 2vw;
        font-weight: 700;
      }
      .bigdata-container {
        width: 100%;
        height: 65vh;
      }
      .prodeucts-container {
        width: 100%;
        height: 25vh;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .slesct-container {
          width: 3vw;
          height: 23vh;
          position: absolute;
          top: 0;
          right: 2.7vw;
          select {
            background-color: #05224d;
            border: none;
            outline: none;
            color: #fff;
            font-size: 1rem;
          }
        }

        #chartContainer {
          width: 80vw;
          height: 60vw;
        }
        .prodeuts-item {
          width: 100%;
          height: 22vh;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          .prodeuts-childone {
            width: 12vw;
            height: 100%;
          }
          .prodeuts-childtwo {
            width: 12vw;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>